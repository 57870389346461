var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"base-chip",class:{
    __s: _vm.s,
    __m: _vm.m,
    __l: _vm.l,
    __xl: _vm.xl,
    __xxl: _vm.xxl,
    __purple: _vm.purple,
    __yellow: _vm.yellow,
    __red: _vm.red,
    __blue: _vm.blue,
    __green: _vm.green,
  }},[(_vm.value)?[_vm._v(" "+_vm._s(_vm.value)+" ")]:[_vm._t("default")]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }