var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"base-icon-button",class:{
    __outline: _vm.outline,
    __disabled: _vm.disabled,
    '__extra-small': _vm.extraSmall,
    __small: _vm.small,
    __medium: _vm.medium,
    __large: _vm.large,
    __onlysize: _vm.onlySize,
  },on:{"click":function($event){return _vm.$emit('click')}}},[_c(_setup.BaseIcon,{attrs:{"path":_vm.iconPath,"width":_vm.width,"height":_vm.height,"screen-size-dependency":""}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }