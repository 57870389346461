<template>
  <div>
    <base-select
      label="Сотрудник"
      :value="id_peopleIn"
      :option-list="people"
      @change="$emit('change', $event.target.value)"
    />
  </div>
</template>

<script setup>
import { request } from "@/components-js/requestSrv";
import BaseSelect from "@/components/BaseSelect";
import { onBeforeMount, ref } from "vue";

const props = defineProps({
  label: String,
  labelPosition: String,
  id_peopleIn: null,
  filter: String,
});

const people = ref(null);

onBeforeMount(async () => {
  if (!props.filter) {
    people.value = await request("/api/user/listall");
    return;
  }
  if (props.filter === "work") people.value = await request("/api/user/listwork");
  people.value.sort((a, b) => {
    if (!a.nickname) a.nickname = a.surname + " " + a.name;
    if (!b.nickname) b.nickname = b.surname + " " + b.name;
    if (a.nickname > b.nickname) return 1;
    if (a.nickname < b.nickname) return -1;
    return 0;
  });
});
</script>

<style lang="scss" scoped>
.people-selector {
  margin-top: 12px;
  padding-left: 10px;

  @media (max-width: 980px) {
    width: 100%;
    margin-top: 6px;

    &__label {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
    }
  }
}
</style>
